import React, {Suspense, lazy, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
const Home = lazy(()=> import('./Home'))
const Gallery = lazy(()=> import('./Gallery'))
const Internshipform = lazy(()=> import('./Internship_form'))
const Testimonals = lazy(()=> import('./Testimonals'))
const Footer = lazy(()=> import('./Footer'))
const Services = lazy(()=> import('./Services'))
const Servicesweprovide = lazy(()=> import('./Servicesweprovide'))

const App = () => {
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = 'img/statsloom_logo.png'; // Change the path to your icon
    }
  }, []);
    return (

        <>
        <Helmet>
        <title>The Statsloom</title>
        <link rel="icon" type="image/png" href="img/statsloom_logo.png" sizes="16x16" />
        <meta name="description" content="Bio Statistics, Equity Research Analyst, Financial Risk Management, Business Analyst services at The Statsloom." />
        <meta property="og:title" content="The Statsloom" />
        <meta property="og:description" content="Bio Statistics, Equity Research Analyst, Financial Risk Management, Business Analyst services at The Statsloom." />
        <meta property="og:image" content="img/statsloom_logo.png" />
        <meta property="og:url" content="thestatsloom.com" />
      </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          {/* <Navbar /> */}

          {/*switch is used to show the pages which we type in url in search bar */}
          <Switch>
            {/* <Route exact path="/" component={Header} /> */}
            <Route exact path="/" component={Home} />
            {/* <Home /> */}
            {/* <Route exact path="/expertise" component={Courses} /> */}
            <Route exact path="/services" component={Services} />
            <Route exact path="/gallery" component={Gallery} />
            {/* exact is used because before words it identifies "/" and goes to home page */}
            <Route exact path="/applyforinternship" component={Internshipform} />
            <Route exact path="/testimonials" component={Testimonals} />
            <Route exact path="/servicesweprovide" component={Servicesweprovide} />
            {/* any other page other than mentioned ones directly send to home page without error */}
            <Redirect to="/" />
          </Switch>
          <Footer />
          </Suspense>
        </>
    );
};

export default App;